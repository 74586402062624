import React from 'react'
import './Footer.css'
// import footerlogo from '../Pics/Miraj Full Logo (2).svg'
import footerlogo from '../Pics/Group 1000008468.svg'

function Footer() {
    return (
        <div>
            <div className='container-fluid footer-div1'>
                <div className='container d-md-flex text-center'>
                    <div>
                        <img src={footerlogo}  alt="" />
                        <p className='footer-text1'>Copyright @ 2024 Master's Builder - All Rights Reserved</p>
                    </div>
                    <div className='footer-div2 row ml-md-auto ml-0 justify-content-center mt-md-3'>
                        <div className='footer-div3'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                                <rect width="35" height="35" transform="translate(0 0.456787)" fill="#EBB42C" />
                                <path d="M18.9074 17.9755V27.4964H14.9681V17.9758H13V14.6949H14.9681V12.725C14.9681 10.0484 16.0793 8.45679 19.2367 8.45679H21.8653V11.7381H20.2223C18.9932 11.7381 18.9119 12.1966 18.9119 13.0524L18.9074 14.6945H21.8839L21.5356 17.9755H18.9074Z" fill="#151623" />
                            </svg>
                        </div>

                        <div className='footer-div3 '>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                                <rect width="35" height="35" transform="translate(0 0.456787)" fill="#EBB42C" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5699 9.45938C16.846 9.45897 17.154 9.45938 17.5001 9.45938C20.2685 9.45938 20.5966 9.46934 21.6899 9.51901C22.7008 9.56527 23.2495 9.73418 23.615 9.87612C24.0988 10.0641 24.4438 10.2887 24.8065 10.6517C25.1694 11.0146 25.3941 11.3603 25.5824 11.8443C25.7243 12.2093 25.8935 12.7581 25.9395 13.7692C25.9891 14.8623 26 15.1907 26 17.9582C26 20.7257 25.9891 21.0541 25.9395 22.1472C25.8932 23.1583 25.7243 23.7071 25.5824 24.0721C25.3945 24.5561 25.1694 24.9007 24.8065 25.2634C24.4436 25.6264 24.0991 25.851 23.615 26.039C23.2499 26.1816 22.7008 26.3501 21.6899 26.3963C20.5969 26.446 20.2685 26.4568 17.5001 26.4568C14.7314 26.4568 14.4033 26.446 13.3103 26.3963C12.2994 26.3497 11.7507 26.1807 11.385 26.0388C10.9011 25.8508 10.5554 25.6261 10.1925 25.2632C9.82964 24.9003 9.60502 24.5555 9.41668 24.0713C9.27477 23.7062 9.10563 23.1575 9.05962 22.1464C9.00988 21.0532 9 20.7248 9 17.9556C9 15.1864 9.00988 14.8597 9.05962 13.7666C9.10579 12.7555 9.27477 12.2067 9.41668 11.8412C9.60461 11.3573 9.82964 11.0116 10.1925 10.6487C10.5554 10.2857 10.9011 10.0611 11.385 9.87263C11.7505 9.73005 12.2994 9.56154 13.3103 9.51512C14.2668 9.47194 14.6375 9.45897 16.5699 9.45679V9.45938ZM23.0348 11.1812C22.3479 11.1812 21.7905 11.738 21.7905 12.4252C21.7905 13.1122 22.3479 13.6695 23.0348 13.6695C23.7217 13.6695 24.279 13.1122 24.279 12.4252C24.279 11.7382 23.7217 11.1812 23.0348 11.1812ZM17.5001 12.6351C14.5595 12.6351 12.1754 15.0196 12.1754 17.9606C12.1754 20.9015 14.5595 23.2849 17.5001 23.2849C20.4407 23.2849 22.8239 20.9015 22.8239 17.9606C22.8239 15.0196 20.4407 12.6351 17.5001 12.6351Z" fill="#151623" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 14.4568C19.4329 14.4568 21 16.0237 21 17.9568C21 19.8897 19.4329 21.4568 17.5 21.4568C15.5669 21.4568 14 19.8897 14 17.9568C14 16.0237 15.5669 14.4568 17.5 14.4568Z" fill="#151623" />
                            </svg>
                        </div>

                        <div className='footer-div3'><svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                            <rect width="35" height="35" transform="translate(0 0.456787)" fill="#EBB42C" />
                            <path d="M24.7932 11.9406C25.5963 12.161 26.2289 12.8104 26.4436 13.6351C26.8337 15.1297 26.8337 18.2484 26.8337 18.2484C26.8337 18.2484 26.8337 21.3669 26.4436 22.8617C26.2289 23.6864 25.5963 24.3357 24.7932 24.5562C23.3376 24.9567 17.5003 24.9567 17.5003 24.9567C17.5003 24.9567 11.6631 24.9567 10.2074 24.5562C9.40417 24.3357 8.77162 23.6864 8.55695 22.8617C8.16699 21.3669 8.16699 18.2484 8.16699 18.2484C8.16699 18.2484 8.16699 15.1297 8.55695 13.6351C8.77162 12.8104 9.40417 12.161 10.2074 11.9406C11.6631 11.54 17.5003 11.54 17.5003 11.54C17.5003 11.54 23.3376 11.54 24.7932 11.9406Z" fill="#151623" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 21.4569V15.6235L20.4167 18.5403L15.75 21.4569Z" fill="#E9B12B" />
                        </svg></div>

                        <div className='footer-div3'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                                <rect width="35" height="35" transform="translate(0 0.456787)" fill="#EBB42C" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.937 22.7233C14.28 22.9046 14.6621 22.9992 15.0499 22.9991C16.3418 22.9991 17.3964 21.97 17.444 20.6873L17.4488 9.2334H20.3024C20.3027 9.47668 20.3253 9.71942 20.3699 9.95856H18.2805V9.95895H21.1344C21.134 10.9176 21.481 11.8438 22.1109 12.5655L22.1117 12.5665C22.7543 12.9868 23.5054 13.2104 24.2729 13.2096V13.8461C24.5415 13.9037 24.8193 13.9344 25.105 13.9344V16.7929C23.6803 16.7946 22.2912 16.3472 21.1345 15.5143V21.3223C21.1345 24.2225 18.778 26.5824 15.8816 26.5824C15.1603 26.5825 14.4467 26.4335 13.7856 26.1446C13.1249 25.856 12.531 25.4339 12.0407 24.9047L12.0396 24.904C10.685 23.9515 9.79706 22.3764 9.79706 20.5971C9.79706 17.6965 12.1535 15.3363 15.0499 15.3363C15.2868 15.3374 15.5233 15.3547 15.7578 15.3879V16.0646C15.7743 16.0643 15.7907 16.0637 15.8071 16.0631C15.8318 16.0623 15.8566 16.0614 15.8816 16.0614C16.1184 16.0625 16.3549 16.0798 16.5894 16.1131V19.0308C16.3656 18.9605 16.1288 18.9195 15.8816 18.9195C15.2455 18.9203 14.6357 19.1737 14.186 19.6241C13.7362 20.0746 13.4833 20.6853 13.4827 21.3223C13.4827 21.8254 13.6415 22.3156 13.9364 22.723L13.937 22.7233ZM10.9752 23.1992C11.219 23.8353 11.5828 24.4123 12.0385 24.9027C11.5735 24.4045 11.2145 23.825 10.9752 23.1992Z" fill="#151623" />
                            </svg>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
